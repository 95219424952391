/**
 * Created by katarinababic on 12.11.24.
 */

/*
 * The NOT EXISTS clause checks if any of the excluded athleteIds exist in the user_properties.
 * If any match is found, that record is excluded from the results.
 * */
const excludeKinasticPeople = `
AND NOT EXISTS (
  SELECT
    1
  FROM
    UNNEST(user_properties) AS p2
  WHERE
    p2.key = 'athleteId'
    AND p2.value.string_value IN ( '84071d4c-713c-4917-b486-b5b43116f3ac',
      'e93005a2-812c-468f-8b75-68f91f3fda30',
      'c29f7cc0-84c0-493f-bebb-09305be44ec6',
      'e9590f2f-b058-46a3-a6ae-cd78d3fd07f5',
      '17436223-c57c-43b9-be47-171743bd45b9',
      '856e8eec-6b96-40ea-a720-708fbd49179f',
      '1c1f7ced-9b12-4268-af44-6da3f469b55c',
      '2847d0b3-698e-4063-8727-6b49cc3de4a6' ) )
`;

export const totalEventsPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_events
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_events DESC
`;

export const totalPageViewsPerGym = `
SELECT
  p.value.string_value gymId,
  e.value.string_value page,
  count (*) total_page_views
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'page_view'
  AND e.key = 'page_location'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId,
  page
ORDER BY
  gymId
`;

export const totalImageDownloadsPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_image_downloads
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadImage'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_image_downloads DESC
`;

export const totalImageDownloadsPerCalendarEntry = `
SELECT
  e.value.string_value image_params,
  count (*) total_image_downloads
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadImage'
  AND e.key = 'event_label'
  ${excludeKinasticPeople}
GROUP BY
  image_params
ORDER BY
  total_image_downloads DESC
`;

export const totalImageDownloadsPerGymPerCalendarEntry = `
SELECT
  p.value.string_value gymId,
  e.value.string_value image_params,
  count (*) total_image_downloads
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadImage'
  AND e.key = 'event_label'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId,
  image_params
ORDER BY
  total_image_downloads DESC
`;

export const totalTextDownloadsPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_text_downloads
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadText'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_text_downloads DESC
`;

export const totalTextDownloadsPerCalendarEntry = `
SELECT
  e.value.string_value calendar_entry_id,
  count (*) total_text_downloads
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadText'
  AND e.key = 'event_label'
  ${excludeKinasticPeople}
GROUP BY
  calendar_entry_id
ORDER BY
  total_text_downloads DESC
`;

export const totalTextDownloadsPerGymPerCalendarEntry = `
SELECT
  p.value.string_value gymId,
  e.value.string_value calendar_entry_id,
  count (*) total_text_downloads
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadText'
  AND e.key = 'event_label'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId,
  calendar_entry_id
ORDER BY
  total_text_downloads DESC
`;

export const totalDownloadAllEventsPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_download_all_count
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadAll'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_download_all_count DESC
`;

export const totalDownloadAllEventsPerCalendarEntry = `
SELECT
  e.value.string_value calendar_entry_id,
  count (*) total_download_all_count
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadAll'
  AND e.key = 'event_label'
  ${excludeKinasticPeople}
GROUP BY
  calendar_entry_id
ORDER BY
  total_download_all_count DESC
`;

export const totalDownloadAllEventsPerGymPerCalendarEntry = `
SELECT
  p.value.string_value gymId,
  e.value.string_value calendar_entry_id,
  count (*) total_download_all_count
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadAll'
  AND e.key = 'event_label'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId,
  calendar_entry_id
ORDER BY
  total_download_all_count DESC
`;

export const totalSearchEventsPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_searches
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'Search'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_searches DESC
`;

export const totalSearchEventsPerGymPerSearchQuery = `
SELECT
  p.value.string_value gymId,
  e.value.string_value search_query,
  count (*) total_searches
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p,
  UNNEST (event_params) e
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'Search'
  AND e.key = 'event_label'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId,
  search_query
ORDER BY
  search_query ASC
`;

export const totalDownloadReportButtonClicksPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_clicks
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'downloadReportDialogOpen'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_clicks DESC
`;

export const totalGenerateReportButtonClicksPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_clicks
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'generatePdfReport'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_clicks DESC
`;

export const totalOpenFolderButtonClicksPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_clicks
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'openFolder'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_clicks DESC
`;

export const totalCreateNotificationButtonClicksPerGym = `
SELECT
  p.value.string_value gymId,
  count (*) total_clicks
FROM
  \`kinasticstudio.analytics_384865426.events_*\`,
  UNNEST (user_properties) p
WHERE
  _TABLE_SUFFIX BETWEEN @start_date
  AND @end_date
  AND event_name = 'createNotification'
  AND p.key = 'gymId'
  ${excludeKinasticPeople}
GROUP BY
  gymId
ORDER BY
  total_clicks DESC
`;
