/**
 * Created by andreaskarantzas on 04.05.20.
 */

import React from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { makeStyles } from 'tss-react/mui';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import Typography from '@mui/material/Typography';
import { runInAction } from 'mobx';
import FormHelperText from '@mui/material/FormHelperText';
import NativeFeedComponent from '../../../../../Model/Feed/NativeFeedComponent';
import { TranslationArrayInput } from '../../../../Translation/TranslationArrayInput';
import Grid from '@mui/material/Grid';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

interface ComponentStylesTextProps {
  component: NativeFeedComponent;
}

const TextAlignStyles = ['left', 'right', 'center', 'justify'];

export const ComponentStylesText: React.FC<ComponentStylesTextProps> = observer((props) => {
  const { classes } = useStyles();
  const { component } = props;

  const store = useLocalStore(
    (source: ComponentStylesTextProps) => ({
      get alignment(): string {
        return 'auto';
      },
      get formatStyles(): string[] {
        const styles: string[] = [];
        if (source.component.props.style.get('fontWeight') === 'bold') {
          styles.push('bold');
        }
        if (source.component.props.style.get('fontStyle') === 'italic') {
          styles.push('italic');
        }
        if (source.component.props.style.get('textDecorationLine') === 'underline') {
          styles.push('underline');
        }
        return styles;
      },
    }),
    props,
  );

  const handleAlignment = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
      if (TextAlignStyles.includes(newAlignment)) {
        runInAction(() => {
          component.props.style.set('textAlign', newAlignment);
        });
      } else {
        component.props.style.set('textAlign', 'auto');
      }
    },
    [component],
  );

  return (
    <Grid className={classes.container}>
      <Grid item xs={12}>
        <FormHelperText>All links must start with https://</FormHelperText>
        <TranslationArrayInput multiline={true} translations={component.text} />
      </Grid>
      {component.type !== 'Summary' && (
        <Grid item xs={12} className={classes.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography>Alignment</Typography>
            <ToggleButtonGroup value={store.alignment} exclusive onChange={handleAlignment} aria-label="text alignment">
              <ToggleButton value="left" aria-label="left aligned">
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton value="center" aria-label="centered">
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton value="right" aria-label="right aligned">
                <FormatAlignRightIcon />
              </ToggleButton>
              <ToggleButton value="justify" aria-label="justified">
                <FormatAlignJustifyIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: 16,
    width: '100%',
  },
  divider: {
    margin: theme.spacing(1, 0.5),
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: '25ch',
  },
}));
