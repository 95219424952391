/**
 * Created by neo on 04.03.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CampaignCalendarEntry } from '../../../../Model/CampaignCalendar/CampaignCalendarEntry';
import { Col, Row } from 'reactstrap';
import { DatePicker, Form, Input, message, Progress, TimePicker } from 'antd';
import { TranslationInputArray } from '../../../../Components/Translation/TranslationInputArray';
import dayjs, { Dayjs } from 'dayjs';
import { runInAction } from 'mobx';
import { GymSelector } from '../../../../Components/GymSelector';
import { Gym } from '../../../../Model/Gym/Gym';
import CreatableSelect from 'react-select/creatable';
import { notUndefined } from '../../../../Utils/notUndefined';
import MediaService from '../../../../Services/MediaService';
import { ViewMediaItem } from '../../../../Components/ViewMedia/ViewMediaItem';
import Dropzone from 'react-dropzone';
import { Media } from '../../../../Model/Media/Media';
import { MediaEditModal } from '../../../MetaData/Media/MediaEditModal/MediaEditModal';

const possibleTags = [
  'academy',
  'kit',
  'article',
  'campaign',
  'spring',
  'summer',
  'autumn',
  'winter',
  'fitness',
  'challenge',
  'nutrition',
  'cardio',
  'strength',
  'ergonomics',
  'recipe',
].sort((a, b) => a.localeCompare(b));

const defaultOptions = possibleTags.map((value) => ({ value, label: value }));

export type CampaignCalendarEntryModalBasicTabProps = {
  campaign: CampaignCalendarEntry;
};

export const CampaignCalendarEntryModalBasicTab: React.FC<CampaignCalendarEntryModalBasicTabProps> = observer(
  ({ campaign }) => {
    const [uploading, setUploading] = useState(false);
    const [uploadPercent, setUploadPercent] = useState(0);
    const [selectedMedia, setSelectedMedia] = useState<Media | undefined>();

    const handleDrop = React.useCallback(
      (files: File[]) => {
        const file = files.length > 0 ? files[0] : undefined;
        if (file) {
          setUploading(true);
          setUploadPercent(0);
          Promise.all(files.map((file) => MediaService.uploadMedia(file)))
            .then((images) => runInAction(() => (campaign.images = campaign.images.concat(images))))
            .catch((e) => console.log('failed', e))
            .finally(() => setUploading(false));
        }
      },
      [campaign],
    );

    const handleChangeDate = React.useCallback(
      (date) => {
        campaign.entryDate = date?.format('YYYY-MM-DD') ?? dayjs().add(1, 'month').format('YYYY-MM-DD');
      },
      [campaign],
    );

    const handleChangeTime = React.useCallback(
      (date) => {
        campaign.entryDate = date?.format('HH:mm:00') ?? dayjs().add(1, 'month').format('HH:mm:00');
      },
      [campaign],
    );

    const handleChangePublishDate = React.useCallback(
      (date) => runInAction(() => (campaign.publishDate = date?.startOf('day').toDate())),
      [campaign],
    );

    const handleChangeUnpublishDate = React.useCallback(
      (date) => runInAction(() => (campaign.unpublishDate = date?.endOf('day').toDate())),
      [campaign],
    );

    const handleChangeGym = React.useCallback(
      (gym?: Gym) => {
        runInAction(() => (campaign.gymId = gym?.id));
      },
      [campaign],
    );

    const handleTagsChange = React.useCallback(
      (tags?: any[]) => {
        runInAction(() => {
          campaign.tags.splice(0, campaign.tags.length);
          (tags ?? []).map((t) => t.value).forEach((t) => campaign.tags.push(t));
        });
      },
      [campaign],
    );

    const handleRemoveImage = React.useCallback(
      (media: Media) => {
        runInAction(() => (campaign.images = campaign.images.filter((v) => v.id !== media.id)));
      },
      [campaign],
    );

    const handleClickMedia = React.useCallback((media: Media) => {
      setSelectedMedia(media);
    }, []);

    const handleCloseMedia = React.useCallback(() => setSelectedMedia(undefined), []);

    const handleMediaSave = React.useCallback(
      (media: Media) => {
        runInAction(() => {
          if (campaign.images.some((v) => v.id === media.id)) {
            const index = campaign.images.findIndex((v) => v.id === media.id);
            if (index !== -1) {
              campaign.images[index] = media;
            }
          }
        });

        campaign.save().then(() => message.success('Media saved'));
      },
      [campaign],
    );

    return (
      <React.Fragment>
        <Row>
          <Col md={3}>
            <Form.Item label="Publish Date">
              <DatePicker
                value={campaign.publishDate ? dayjs(campaign.publishDate) : undefined}
                onChange={handleChangePublishDate}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col md={3}>
            <Form.Item label="Unpublish Date">
              <DatePicker
                value={campaign.unpublishDate ? dayjs(campaign.unpublishDate) : undefined}
                onChange={handleChangeUnpublishDate}
                allowClear={true}
              />
            </Form.Item>
          </Col>
          <Col xs={3}>
            <Form.Item label="Campaign Date">
              <DatePicker showTime={false} value={campaign.localDateTime} onChange={handleChangeDate} />
            </Form.Item>
          </Col>
          <Col xs={3}>
            <Form.Item label="Campaign Time">
              <TimePicker
                value={campaign.localDateTime}
                minuteStep={15}
                showSecond={false}
                onChange={handleChangeTime}
              />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Optional Company">
              <GymSelector gymId={campaign.gymId} onChange={handleChangeGym} />
            </Form.Item>
          </Col>
          <Col xs={6}>
            <Form.Item label="Entry Tags">
              <CreatableSelect
                isClearable
                isMulti
                options={defaultOptions}
                onChange={handleTagsChange as any}
                value={campaign.tags.map((t) => ({ value: t, label: t })) as any}
                placeholder="Tags"
              />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Name">
              <TranslationInputArray entity={campaign} field={'name'} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Description">
              <TranslationInputArray type="textarea" entity={campaign} field={'description'} markdown={true} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Instructions">
              <TranslationInputArray type="textarea" entity={campaign} field={'instructions'} />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label="Image(s)">
              {campaign.images.map((media) => (
                <React.Fragment key={media.id}>
                  <ViewMediaItem media={media} onRemove={handleRemoveImage} onClick={handleClickMedia} />
                </React.Fragment>
              ))}
              <React.Fragment>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      className="dropzone"
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <input {...getInputProps()} />
                      Drag & Drop Image
                    </div>
                  )}
                </Dropzone>
                {uploading && (
                  <Progress
                    type="circle"
                    percent={uploadPercent}
                    format={(percent) => ((percent ?? 0) >= 100 ? 'Uploading' : `${percent?.toFixed(2)}%`)}
                  />
                )}
              </React.Fragment>
            </Form.Item>
          </Col>
        </Row>
        {selectedMedia && <MediaEditModal media={selectedMedia} onCancel={handleCloseMedia} onSave={handleMediaSave} />}
      </React.Fragment>
    );
  },
);
