/**
 * Created by katarinababic on 20.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { CalendarEntry } from './StudioAnalyticsScreen';
import dayjs from 'dayjs';
import { AnalyticsData } from '../../../Model/Analytics/AnalyticsData';
import { totalDownloadAllEventsPerCalendarEntry } from './studioAnalyticsQueries';
import { Col, Row, Spinner, Table } from 'reactstrap';
import { DatePicker, Form, Space } from 'antd';

const colStyle = {
  paddingTop: 32,
};

const spinnerColStyle = {
  paddingTop: 80,
  paddingBottom: 80,
};

const rowStyle = {
  paddingTop: 16,
};

type TableRow = {
  entryId: string;
  entryName: string;
  entryDate: string;
  totalDownloads: number;
};

export type StudioTotalDownloadAllEventsPerEntryProps = {
  entries: CalendarEntry[];
};

export const StudioTotalDownloadAllEventsPerEntry: React.FC<StudioTotalDownloadAllEventsPerEntryProps> = observer(
  ({ entries }) => {
    const [startDate, setStartDate] = useState(dayjs('2024-08-01', 'YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs());
    const [tableData, setTableData] = useState<TableRow[]>([]);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
      setTableData([]);
      setLoading(true);
      AnalyticsData.query({
        query: totalDownloadAllEventsPerCalendarEntry,
        parameters: {
          start_date: {
            value: startDate.format('YYYYMMDD'),
          },
          end_date: {
            value: endDate.format('YYYYMMDD'),
          },
        },
      })
        .then((res) => {
          const displayData = res
            .map((row) => {
              return {
                entryId: row['calendar_entry_id'].toLowerCase(),
                totalDownloads: parseInt(row['total_download_all_count']),
              };
            })
            .map((row) => ({
              ...row,
              entryName: entries.find((entry) => entry.id === row.entryId)?.name ?? 'Unknown',
              entryDate: entries.find((entry) => entry.id === row.entryId)?.date ?? 'Unknown',
            }));
          setTableData(displayData);
        })
        .finally(() => setLoading(false));
    }, [startDate, endDate]);

    const handleChangeStartDate = React.useCallback((date) => setStartDate(date ?? dayjs().subtract(1, 'month')), []);

    const handleChangeEndDate = React.useCallback((date) => setEndDate(date ?? dayjs()), []);

    return loading ? (
      <Row>
        <Col style={spinnerColStyle}>
          <Spinner />
        </Col>
      </Row>
    ) : (
      <React.Fragment>
        <Row style={rowStyle}>
          <Col style={colStyle}>
            <h5>{'Total "download all" events per calendar entry'}</h5>
            <h6 style={{ fontStyle: 'italic' }}>
              {
                'This event is triggered when the user clicks on "Download all" to download all files at once as zip in the calendar entry content page.'
              }
            </h6>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Col>
            <Space>
              <Form.Item label="Start Date">
                <DatePicker value={startDate} onChange={handleChangeStartDate} minDate={dayjs('2024-08-01')} />
              </Form.Item>
              <Form.Item label="End Date">
                <DatePicker value={endDate} onChange={handleChangeEndDate} />
              </Form.Item>
            </Space>
          </Col>
        </Row>
        <Row style={rowStyle}>
          <Table>
            <thead>
              <tr>
                <th>{'Calendar entry id'}</th>
                <th>{'Calendar entry name'}</th>
                <th>{'Calendar entry date'}</th>
                <th>{'Total downloads'}</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row) => (
                <tr key={row.entryId}>
                  <td>{row.entryId}</td>
                  <td>{row.entryName}</td>
                  <td>{row.entryDate}</td>
                  <td>{row.totalDownloads}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </React.Fragment>
    );
  },
);
